import React from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import TrackView from './Views/TrackView';
import DefaultView from './Views/DefaultView';


export default function App() {

  return (
    <BrowserRouter>
      <Routes>
        <Route path="*" element={<DefaultView text={"Hello!"} />}></Route>
        <Route path="/t" element={<TrackView />}></Route>
      </Routes>
    </BrowserRouter>
  );
}