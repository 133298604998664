
import React, { useEffect, useState, } from 'react';
import MapView from './MapView';
import UserProfile from './UserProfile';
import { getFirestore, doc, onSnapshot } from "firebase/firestore";
import firebaseApp from '../firebase';
import DefaultView from './DefaultView';
import { useSearchParams } from 'react-router-dom';
import { useJwt } from "react-jwt";
// import { getSecretValue } from './secreteManager';

const db = getFirestore(firebaseApp);

function TrackView() {
    let [searchParams] = useSearchParams();
    let token = searchParams.get("token")

    // console.log(`token ${token}`)
    const { decodedToken, isExpired } = useJwt(token);
    console.log(isExpired)
    if (!token) {
        return <DefaultView text="No token found" />
    }
    if (!decodedToken) {
        return <DefaultView text="Invalid token" />
    }
    // console.log(decodedToken)

    var childId = decodedToken.childId
    var tripHistoryId = decodedToken.tripHistoryId

    if (!childId) {
        return <DefaultView text="Child details not found" />

    }
    if (!tripHistoryId) {
        return <DefaultView text="Loading..." />
    }

    return <TrackViewBody childId={childId} tripHistoryId={tripHistoryId} />

}

function TrackViewBody({ childId, tripHistoryId, }) {
    const [tripData, setTripData] = useState(null);
    var childHomeLocation = { lat: 0, lng: 0 }
    useEffect(() => {
        const unSub = onSnapshot(doc(db, "tripHistory", tripHistoryId), (doc) => {
            if (doc.exists) {
                const data = doc.data();
                console.log(data, 'data');
                setTripData(data);
            } else {
                // console.log('Trip not found');
                setTripData(null);
            }
        });

        return () => {
            unSub();
        };
    }, [tripHistoryId]);



    if (!tripData) {
        return <DefaultView text="Loading..." />
    }

    if (!childId || !tripData[childId]) {
        return <DefaultView text="Child details not found" />
    }

    if (!tripData) {
        return <DefaultView text="Trip not found" />
    }
    const tripDetails = tripData.tripDetails
    var tripStatus = tripDetails['tripStatus']
    if (tripStatus === 'stopped' || tripStatus === 'completed') {
        return <DefaultView text="This trip is completed" />
    }
    var childDetails = tripData[childId];
    if (childDetails === null) {
        return <DefaultView text="Child details not found" />
    }
    var childHome = childDetails.homeAddress
    childHomeLocation = { lat: childHome.lat, lng: childHome.lng }

    var acknowledged = childDetails['acknowledged'] === true ||
        childDetails['parentAcknowledgedSchoolDrop'] === true;
    if (acknowledged) {
        return <DefaultView text="Trip completed for this child and Parent acknowledged drop" />
    }

    return <div className='tack-body'>
        <MapView tripId={tripData.tripDetails.tripId} childHomeLocation={childHomeLocation} childDetails={childDetails} tripData={tripData?.tripDetails} />
        <UserProfile className='driver-body' userId={tripData.tripDetails.driverId} childDetail={childDetails} tripData={tripData?.tripDetails} />
    </div>

}

export default TrackView;

// const token = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0cmlwSGlzdG9yeUlkIjoiZjMwMmU5MjktNzRiZC00Nzg3LTk1ZDctNTY1ZmY2MjM3YzM1IiwiY2hpbGRJZCI6ImM2NjYwZTFhLWM1N2ItNDA5Zi1hZjc1LTJhYjM1NmYxZWYyMiIsInRyaXBJZCI6IjlmYjdmOGY0LTA2NWUtNGZmOC04ZjgxLTlmODVjYjk2YjI3NCIsImlhdCI6MTY5NDc1ODg0M30.HK0ch1HCKqAAn7nux5ThdW6UZvNKRNTWWcaRr9XQCXQ"