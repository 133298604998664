import React, { useEffect, useState } from 'react';
import { getDatabase, ref, onValue } from 'firebase/database';
import { GoogleMap, useLoadScript, MarkerF } from "@react-google-maps/api";
import customMarkerIcon from '../images/sb_web.png';
import childMarkerIcon from '../images/childMarkerIcon.png';
import schoolMarkerIcon from '../images/schoolIcon.png';
import firebaseApp from '../firebase';


const db = getDatabase(firebaseApp);
const googleMapsApiKey = firebaseApp._options.googleMapsApiKey;

// const serverUrl = process.env.REACT_APP_SERVER_URL; 

function MapView({ tripId, childHomeLocation, childDetails, }) {
  const [busLocation, setBusLocation] = useState({ lat: 0, lng: 0 });

  useEffect(() => {
    const databasePath = `/activeRides/${tripId}/d_loc`;
    const starCountRef = ref(db, databasePath);

    const onDataChange = (snapshot) => {
      const data = snapshot.val();
      if (data) {

        setBusLocation({ lat: data[0], lng: data[1] });
      }
    };

    const onError = (error) => {
      console.error(error);
    };

    const databaseListener = onValue(starCountRef, onDataChange, onError);

    return () => {
      databaseListener();
    };
  }, [tripId]);



  const { isLoaded } = useLoadScript({
    googleMapsApiKey: googleMapsApiKey,
  });

  return (
    <div className="map-container">
      {isLoaded && (
        <GoogleMap
          mapContainerStyle={{ width: '100%', height: '85vh' }}
          zoom={16}
          center={busLocation}
        >
          <MarkerF
            zIndex={0}
            position={childDetails?.schoolAddress}
            icon={{
              url: schoolMarkerIcon,
              scaledSize: new window.google.maps.Size(43, 43),
            }}
          />
          <MarkerF
            zIndex={1}
            position={busLocation}
            icon={{
              url: customMarkerIcon,
              scaledSize: new window.google.maps.Size(40, 40),
            }}
          />
          <MarkerF
            zIndex={0}
            position={childHomeLocation}
            icon={{
              url: childMarkerIcon,
              scaledSize: new window.google.maps.Size(43, 43),
            }}
          />

        </GoogleMap>
      )}
    </div>
  );
}

export default MapView;
