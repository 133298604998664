import React from 'react';
import '../App.css';
import appLogo from '../images/app_logo.png';
function DefaultView({ text }) {
  return (
    <div className="main-container">
      <img src={appLogo} alt="App Logo" className="app-logo" />
      <h1 className="app-name">SkoolBus</h1>
      <h3>Ensure your Child's Safety from Anywhere.</h3>
      <div className="note">
        {/* <h4>Note</h4> */}
        <p>{text}</p>
      </div>
    </div>
  );
}

export default DefaultView;