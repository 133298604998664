import React, { useState, useEffect } from 'react';
import firebaseApp from '../firebase';
import { getFirestore, doc, getDoc } from "firebase/firestore";
import appLogo from '../images/app_logo.png';

import DriverAvatar from '../images/driver-avatar.png'
import ChildAvatar from '../images/child-avatar.png'

const UserProfile = ({ userId, childDetail, tripData }) => {
  const [driverData, setDriverData] = useState(null);
  const [childData, setChildData] = useState(null);

  useEffect(() => {
    console.log(childDetail);
    setChildData(childDetail)
    // Initialize Firebase
    const db = getFirestore(firebaseApp);

    // Fetch user data based on the document ID
    const getUserData = async () => {
      try {
        const userRef = doc(db, "drivers", userId);
        const docSnap = await getDoc(userRef);
        // console.log(userRef)
        // console.log(docSnap)
        if (docSnap.exists) {
          const userData = docSnap.data();
          if (tripData?.currentDriver === "secondaryDriver") {
            setDriverData(userData?.secondaryDriver);
          }else{
            setDriverData(userData);
          }
        } else {
          // console.log('User not found');
        }
      } catch (error) {
        console.error('Error fetching user data:', error);
      }
    };

    getUserData();
  }, [userId, childDetail, tripData?.currentDriver]);

  return (
  <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
    <div className='row-md'>
      {childData ?
        <div className='driver-body divider-md'>
        <h3 className='title'>Child Details</h3>
        <div className='row' style={{display: 'flex'}}>
          <div className='column'>
          {childData.photoUrl ? (
            <img src={childData.photoUrl} alt={`${childData.name}'s profile`} className='profile-img' />
          )
            :
            (
              <img src={ChildAvatar} alt={`${childData.name}'s profile`} className='profile-img' />
            )
          }</div>
        
          <div className='column' style={{flex: 1}}>
            <p className='name'>{childData.name}</p>
            <p className='vehicle'>{childData?.homeAddress?.name}</p>
          </div>

          </div>

        </div>
      : 
      <p>Loading user data...</p>}
      {driverData ? 
        <div className='driver-body'>
          <h3 className='title'>Driver Details</h3>
          <div className='row' style={{display: "flex", }}>
          <div className='column' >
          {driverData.photoUrl ? (
              <img  src={driverData.photoUrl} alt={`${driverData.name}'s profile`} className='profile-img' />
            )
            :
            (
              <img src={DriverAvatar} alt="" className='profile-img' />
            )
          }</div>
          
            <div className='column' style={{flex: 1}}>
              <p className='name'>{driverData?.name}</p>
              <p className='mobile'>{driverData?.mobileNumber}</p>
              <p className='vehicle'>{driverData?.vehicleInfo?.vehicleLicence||''}</p>
            </div>

          </div>

        </div>
      : 
        <p>Loading user data...</p>}
    </div>
      <img src={appLogo} alt="App Logo" className="app-logo-nav" />
  </div>);
};

export default UserProfile;
