
import { initializeApp } from "firebase/app";
import axios from 'axios';

const fetchSecret = async () => {
  try {
    const response = await axios.get('https://track-backend.skoolbus.app/secret');
    return JSON.parse(response.data.secret);
  } catch (error) {
    console.error('Error fetching the secret:', error);
  }
};

const secret = await fetchSecret();

const firebaseConfig = {
  apiKey: secret.REACT_APP_API_KEY,
  authDomain: secret.REACT_APP_AUTH_DOMAIN,
  databaseURL: secret.REACT_APP_DATABASE_URL,
  projectId: secret.REACT_APP_PROJECT_ID,
  storageBucket: secret.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: secret.REACT_APP_MESSAGING_SENDER_ID,
  appId: secret.REACT_APP_APP_ID,
  measurementId: secret.REACT_APP_MEASUREMENT_ID,
  googleMapsApiKey: secret.REACT_APP_GOOGLE_MAPS_API_KEY
};
// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig);

export default firebaseApp;
